import { useQuery } from "@tanstack/react-query";
import {
  getCandidateProfiles,
  getSingleCandidateProfile,
} from "../endpoints/candidates";

export const useGetCandidateProfiles = ({
  prof_liked, gender, move_in_date, max_rent, pets, age
}: {
  prof_liked: string;
  gender: string;
  move_in_date: string;
  max_rent: number;
  pets: string;
  age: Array<number>;
}) => {
  return useQuery({
    queryKey: ["getCandidates", { prof_liked, gender, move_in_date, max_rent, pets, age}],
    queryFn: getCandidateProfiles,
    staleTime: Infinity,
    retry: true,
  });
};

export const useGetSingleCandidateProfile = ({
  user_id,
}: {
  user_id: string;
}) => {
  return useQuery({
    queryKey: ["getCandidate", { user_id }],
    queryFn: getSingleCandidateProfile,
    staleTime: 0,
    retry: true,
  });
};
