import { ErrorMessage, Field, Form, Formik } from "formik";
import { UserDetails } from "../../../types/userInfo";
import * as Yup from "yup";
import clsx from "clsx";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../ui/select";
import { MultiSelect } from "../ui/multiSelect";
import { Slider } from "../ui/slider";
import { RadioGroup, RadioGroupItem } from "../ui/radioGroup";
import { Label } from "../ui/label";
import { useState } from "react";
import { cities, cityRegions } from "../../utils/helper";
import { useGetUserInfo } from "../../api/hooks/user";



interface FormValues {
  city_of_interest: string;
  regions: string[] | null;
  budget_max: number;
  preferred_move_in_date: string | null;
  is_smoker: boolean | null;
  has_pets: boolean | null;
  cleaning_habits: string | null;
}


const validationSchema = Yup.object({
  city_of_interest: Yup.string().required("City of interest is required"), // Mandatory
  budget_max: Yup.number().required("Budget is required"), // Mandatory
  preferred_move_in_date: Yup.string()
    .required("Preferred move-in date is required")
    .nullable(), // Mandatory
  cleaning_habits: Yup.string().required("Cleaning habits are required"), // Mandatory
  is_smoker: Yup.boolean().nullable(), // Optional
  has_pets: Yup.boolean().nullable(), // Optional
  regions: Yup.array().nullable(), // Optional
});

export const FormStepTwo = ({
  handleSubmit,
  handleBack,
}: {
  handleSubmit: (values: Partial<UserDetails>) => void;
  handleBack: () => void;
}) => {
  const { data: userData } = useGetUserInfo();
  const [moveInOption, setMoveInOption] = useState<string>("");

  const initialValues: FormValues = {
    city_of_interest: userData?.city_of_interest || "",
    regions: userData?.regions || null,
    budget_max: userData?.budget_max || 0,
    preferred_move_in_date: userData?.preferred_move_in_date || "",
    is_smoker: userData?.allow_smoking || false,
    has_pets: userData?.allow_pet || false,
    cleaning_habits: userData?.cleaning_habits || "0",
  };

  return (
    <div className="flex flex-col gap-4 mx-auto w-8/12">
      <p className="text-slate-600 text-xl text-center">
        Tell us a bit about yourself to find your ideal roommate
      </p>
      <div className="flex flex-col">
        <p className="text-lg font-medium">My Preferences</p>
        <p>* Required fields</p>
      </div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, setFieldValue, values, isValid }) => {
          return (
            <Form className="space-y-4">
              {/* City of interest */}
              <div>
                <p>What's your City of interest? *</p>
                <div className="flex gap-2">
                  <div className="w-1/2">
                    <Select
                      value={values.city_of_interest}
                      onValueChange={(value) => {
                        setFieldValue("city_of_interest", value);
                      }}
                    >
                      <SelectTrigger>
                        <SelectValue placeholder="Select a city of interest" />
                      </SelectTrigger>
                      <SelectContent className="max-h-[200px]">
                        {cities.map((city, index) => (
                          <SelectItem key={index} value={city}>
                            {city}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                    <ErrorMessage
                      name="city_of_interest"
                      component="div"
                      className="text-red-500 text-sm"
                    />
                  </div>
                </div>
              </div>
              <div className="w-1/2">
                <p>What are your preferred regions?</p>
                <MultiSelect
                  disabled={values.city_of_interest === ""}
                  options={
                    !!values.city_of_interest
                      ? cityRegions[values.city_of_interest]
                      : []
                  }
                  onValueChange={(value) => {
                    setFieldValue("regions", value);
                  }}
                  defaultValue={
                    values.regions === null ? [] : values.regions
                  }
                  placeholder="Select"
                  maxCount={3}
                />
                <ErrorMessage
                  id="regions"
                  name="regions"
                  component="div"
                  className="text-red-500 text-sm"
                />
              </div>
              <div className="w-1/2">
                <p className="pb-[10px]">
                  What is your preferred move in date?
                </p>
                <RadioGroup
                  defaultValue=""
                  value={moveInOption}
                  onValueChange={(value) => {
                    setMoveInOption(value);
                    if (value !== "custom")
                      setFieldValue("preferred_move_in_date", value);
                  }}
                >
                  <div className="flex items-center space-x-2">
                    <RadioGroupItem value="asap" id="asap" />
                    <Label htmlFor="asap">As soon as possible</Label>
                  </div>
                  <div className="flex items-center space-x-2">
                    <RadioGroupItem value="na" id="na" />
                    <Label htmlFor="na">I don't have a date yet</Label>
                  </div>
                  <div className="flex items-center space-x-2 text-nowrap">
                    <RadioGroupItem value="custom" id="custom" />
                    <Label htmlFor="custom">Specific Date</Label>
                    {moveInOption === "custom" && (
                      <Field
                        type="date"
                        name="preferred_move_in_date"
                        id="preferred_move_in_date"
                        className="border p-2 rounded w-full"
                      />
                    )}
                  </div>
                </RadioGroup>
              </div>
              {/* Budget */}
              <div className="w-1/2">
                <p className="pb-[10px]">What is your budget? (per month) *</p>
                <Slider
                  value={[values.budget_max ?? 0]}
                  defaultValue={[2000]}
                  max={2000}
                  step={10}
                  onValueChange={(value) => {
                    setFieldValue("budget_max", value[0]);
                  }}
                />
                <ErrorMessage
                  name="budget_max"
                  component="div"
                  className="text-red-500 text-sm"
                />
              </div>

              {/* Has Pets */}
              <div className="w-1/2">
                <p className="pb-[10px]">Do you have any pets?</p>
                <RadioGroup
                  defaultValue=""
                  value={
                    values.has_pets === null
                      ? undefined
                      : values.has_pets?.toString()
                  }
                  onValueChange={(value) => {
                    const finalValue = value === "true";
                    setFieldValue("has_pets", finalValue);
                  }}
                >
                  <div className="flex gap-4">
                    <div className="flex items-center space-x-2">
                      <RadioGroupItem value="true" id="true" />
                      <Label htmlFor="true">Yes</Label>
                    </div>
                    <div className="flex items-center space-x-2">
                      <RadioGroupItem value="false" id="false" />
                      <Label htmlFor="false">No</Label>
                    </div>
                  </div>
                </RadioGroup>
                <ErrorMessage
                  name="has_pets"
                  component="div"
                  className="text-red-500 text-sm"
                />
              </div>

              {/* Is Smoker */}
              <div className="w-1/2">
                <p className="pb-[10px]">Are you a smoker?</p>
                <RadioGroup
                  defaultValue=""
                  value={
                    values.is_smoker === null
                      ? undefined
                      : values.is_smoker?.toString()
                  }
                  onValueChange={(value) => {
                    const finalValue = value === "true";
                    setFieldValue("is_smoker", finalValue);
                  }}
                >
                  <div className="flex gap-4">
                    <div className="flex items-center space-x-2">
                      <RadioGroupItem value="true" id="true" />
                      <Label htmlFor="true">Yes</Label>
                    </div>
                    <div className="flex items-center space-x-2">
                      <RadioGroupItem value="false" id="false" />
                      <Label htmlFor="false">No</Label>
                    </div>
                  </div>
                </RadioGroup>
                <ErrorMessage
                  name="is_smoker"
                  component="div"
                  className="text-red-500 text-sm"
                />
              </div>

                {/* Cleaning Habits */}
                <div className="w-1/2">
                <p className="pb-[10px]">What are your cleaning habits? *</p>
                <div className="flex gap-3 items-center">
                  <p className="text-sm">Relaxed</p>
                  <div className="hidden sm:block"></div>
                  <Slider
                    showValues={false}
                    value={[
                    Number(
                      values.cleaning_habits === null
                      ? 0
                      : values.cleaning_habits,
                    ),
                    ]}
                    max={2}
                    step={1}
                    onValueChange={(value) => {
                    setFieldValue("cleaning_habits", value[0].toString());
                    }}
                  />
                  </div>
                  <div className="block sm:hidden">
                  <select
                    value={values.cleaning_habits ?? "0"}
                    onChange={(e) => {
                    setFieldValue("cleaning_habits", e.target.value);
                    }}
                    className="border p-2 rounded"
                  >
                    <option value="0">Relaxed</option>
                    <option value="1">Moderate</option>
                    <option value="2">Very tidy</option>
                  </select>
                  </div>
                  <p className="text-sm text-nowrap">Very tidy</p>
                </div>
                <ErrorMessage
                  name="cleaning_habits"
                  component="div"
                  className="text-red-500 text-sm"
                />
                {/* </div> */}

              {/* Submit Button */}
              <div className="flex p-4 justify-end gap-4">
                <button
                  type="button"
                  onClick={handleBack}
                  className={clsx(
                  "bg-gray-300 text-white px-8 py-2 rounded-lg text-lg cursor-pointer sm:px-16 sm:py-4"
                  )}
                >
                  Back
                </button>
                <button
                  type="submit"
                  disabled={isSubmitting || !isValid}
                  className={clsx(
                  "bg-[#32936F] text-white px-8 py-2 rounded-lg text-lg cursor-pointer sm:px-16 sm:py-4",
                  (isSubmitting || !isValid) && "opacity-70"
                  )}
                >
                  Next
                </button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};
