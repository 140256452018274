import { useEffect, useRef, useState } from "react";
import { useGetChatsForUser, useGetChatData } from "../api/hooks/useChat";
import { useGetUserInfo } from "../api/hooks/user";
import { ChatList } from "../components/chat/chatList";
import { Message } from "../components/chat/message";
import { SendBar } from "../components/chat/sendBar";
import { ChatListData, ChatListItem, ChatMessage } from "../../types/chatInfo";
import { queryClient } from "../api/queryClient";
import { Search } from "../components/ui/search";

export const ChatPage = () => {
  const [userId, setUserId] = useState("")
  const { data: userInfo, isLoading: userInfoLoading } = useGetUserInfo();
  const [chatId, setChatID] = useState("")

  const chatContainerRef = useRef<HTMLDivElement>(null);
  const [chatMessages , setChatMessages] = useState([]);

  const { data: chats, isLoading: chatsLoading, isError: chatsError} = useGetChatsForUser({user_id: userId});
  const { data: chat_messages, isLoading: chatsMessagesLoading, isError: chatsMessagesError} = useGetChatData({chat_id: chatId});

  useEffect(()=>{
    if(userInfo){
      setUserId(userInfo.id);
    } else {
      setUserId("");
    }
  }, [userInfo])


  useEffect(()=>{
    if(chat_messages){
      setChatMessages(chat_messages.messages);
    }
  }, [chat_messages])


  const handleChatClick = (chatItem: ChatListItem) => {
    setChatID(chatItem.id);
    queryClient.invalidateQueries({queryKey: ["getChatData"]});
  };

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  }, [chatMessages]);

  const chat = chats?.data?.find((chatItem: ChatListItem) => chatItem.id === chatId);

  const handleSearch = (searchTerm: string) => {
    console.log("Search Term:", searchTerm);
  };

  useEffect(() => {
    if (chats && chats.data && chats.data.length > 0 && !chatId) {
      // Automatically select the first chat if there's no chatId set
      const firstChatId = chats.data[0].id;
      setChatID(firstChatId);
      queryClient.invalidateQueries({ queryKey: ["getChatData"] });
    }
  }, [chats, chatId]);

  return(
    <div className="flex flex-row mt-1 max-h-full " style={{ height: "calc(100vh - 8rem)" }}>
      <div className="flex flex-col w-1/3 flex-start">
      <span className="font-medium text-[2rem] leading-[3.5rem]">Chats</span>
        <Search onSearch={handleSearch}></Search>
        <ChatList list={chats} handleChatListClick={handleChatClick}></ChatList>
      </div>
      <div className="flex-1 flex flex-col">
      {chat && (
        <div className="ml-2 flex items-center space-x-4 mt-2">
          <img
            src={chat.sender?.avatar || "/default-avatar.png"}
            alt={chat.sender?.name}
            className="w-12 h-12 rounded-full object-cover"
          />
          <span className="font-bold text-xl">{chat.sender?.name || "Unknown User"}</span>
        </div>
      )}
        {chat && (
        <div className="ml-2 mt-2 h-[4px] bg-gray-300 shadow-md w-full rounded-full"></div>
        )}
        <div ref={chatContainerRef} className="w-full shadow-md ml-2 h-full overflow-y-auto scroll-smooth">
          {chatMessages &&
            chatMessages.map((mes: ChatMessage) => {
              const senderName = mes.sender === userId ? "" : chat?.sender.name;
              const senderAvatar = mes.sender === userId ? "" : chat?.sender.avatar;
              return(
                <Message from={mes.sender == userId} message={mes} sender={senderName} avatar={senderAvatar}/>
              )
            })}
        </div>
        {chat && <SendBar chat_id= {chat.id }/>}
      </div>
    </div>
  )
};
