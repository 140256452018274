import { SlidersHorizontal } from "lucide-react";
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "../ui/sheet";
import { DualRangeSlider } from "../ui/dualRangeSlider";
import { RadioGroup, RadioGroupItem } from "../ui/radioGroup";
import { Label } from "../ui/label";
import { Separator } from "../ui/separator";
import { Slider } from "../ui/slider";
import { Checkbox } from "../ui/checkbox";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../ui/select";

export const RoomatesFilters = ({
  queryParams,
  setQueryParams,
}: {
  queryParams: any;
  setQueryParams: (value: any) => void;
}) => {
  return (
    <Sheet>
      <SheetTrigger>
        <div className="text-white bg-black rounded-full text-white px-3 shadow flex p-1 gap-1 items-center">
          <SlidersHorizontal size={18} />
          Filters
        </div>
      </SheetTrigger>
      <SheetContent side={"left"}>
        <SheetHeader>
          <SheetTitle>Filters</SheetTitle>
          <SheetDescription>Customize your search</SheetDescription>
        </SheetHeader>

        <div className="flex flex-col pt-4 gap-4">
          <Separator />

          <p className="font-medium">Basic</p>
          <div className="flex flex-col gap-2">
            <p>View Settings</p>
            <RadioGroup
              defaultValue="male"
              value={queryParams.prof_liked}
              onValueChange={(value) => {
                setQueryParams((prev: any) => ({
                  ...prev,
                  prof_liked: value,
                }));
              }}
            >
              <div className="flex items-center space-x-2">
                <RadioGroupItem value="all" id="all" />
                <Label htmlFor="all">View all</Label>
              </div>
              <div className="flex items-center space-x-2">
                <RadioGroupItem value="linked" id="linked" />
                <Label htmlFor="linked">View linked</Label>
              </div>
              <div className="flex items-center space-x-2">
                <RadioGroupItem value="new_only" id="new_only" />
                <Label htmlFor="new_only">View new</Label>
              </div>
            </RadioGroup>
          </div>
          <div className="flex flex-col gap-2">
            <p>Age</p>
            <DualRangeSlider
              label={(value) => value}
              value={queryParams.age}
              onValueChange={(value) => {
                setQueryParams((prev: any) => ({ ...prev, age: value }));
              }}
              min={18}
              max={100}
              step={1}
            />
          </div>
          <div className="flex flex-col gap-2">
            <p>Gender</p>
            <RadioGroup
              defaultValue="male"
              value={queryParams.gender}
              onValueChange={(value) => {
                setQueryParams((prev: any) => ({ ...prev, gender: value }));
              }}
            >
              <div className="flex items-center space-x-2">
                <RadioGroupItem value="male" id="male" />
                <Label htmlFor="male">Male</Label>
              </div>
              <div className="flex items-center space-x-2">
                <RadioGroupItem value="female" id="female" />
                <Label htmlFor="female">Female</Label>
              </div>
              <div className="flex items-center space-x-2">
                <RadioGroupItem value="any" id="any" />
                <Label htmlFor="any">Any</Label>
              </div>
              <div className="flex items-center space-x-2">
                <RadioGroupItem value="Non_binary" id="non_binary" />
                <Label htmlFor="non_binary">Non-binary</Label>
              </div>
            </RadioGroup>
          </div>
          <div className="flex flex-col gap-2">
            <p>Preferred move in date</p>
            <RadioGroup
              defaultValue=""
              value={queryParams.move_in_date}
              onValueChange={(value) => {
                setQueryParams((prev: any) => ({
                  ...prev,
                  move_in_date: value,
                }));
              }}
            >
              <div className="flex items-center space-x-2">
                <RadioGroupItem value="asap" id="asap" />
                <Label htmlFor="asap">As soon as possible</Label>
              </div>
              <div className="flex items-center space-x-2">
                <RadioGroupItem value="na" id="na" />
                <Label htmlFor="na">I don't have a date yet</Label>
              </div>
            </RadioGroup>
          </div>
          <Separator />
          <p className="font-medium">Badget</p>
          <div className="flex flex-col gap-2">
            <p>Maximum rent</p>
            <Slider
              value={[queryParams.max_rent]}
              defaultValue={[3000]}
              max={6000}
              step={10}
              onValueChange={(value) => {
                setQueryParams((prev: any) => ({
                  ...prev,
                  max_rent: value[0],
                }));
              }}
            />
            <div className="flex justify-between items-center">
              <p>Including Utilities</p>
              <Checkbox
                value={queryParams.utilities}
                onCheckedChange={(value) => {
                  setQueryParams((prev: any) => ({
                    ...prev,
                    utilities: value,
                  }));
                }}
              />
            </div>
          </div>
          <div className="flex flex-col gap-2">
            <p>Preffered Neighbourhood</p>
            <Select
              value={queryParams.preferred_location}
              onValueChange={(value) => {
                setQueryParams((prev: any) => ({
                  ...prev,
                  preferred_location: value,
                }));
              }}
            >
              <SelectTrigger>
                <SelectValue placeholder="Select a neighbourhood" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="0">Ampelokimpoi</SelectItem>
                <SelectItem value="1">Zografou</SelectItem>
                <SelectItem value="2">Agios Ioannis</SelectItem>
                <SelectItem value="3">Koukaki</SelectItem>
                <SelectItem value="4">Exarcheia</SelectItem>
              </SelectContent>
            </Select>
            <div className="flex justify-between items-center">
              <p>Including Near by areas</p>
              <Checkbox
                value={queryParams.areas_near_by}
                onCheckedChange={(value) => {
                  setQueryParams((prev: any) => ({
                    ...prev,
                    areas_near_by: value,
                  }));
                }}
              />
            </div>
          </div>
          <Separator />
          <p className="font-medium">Lifestyle</p>
          <div className="flex flex-col gap-2">
            <p>Pets</p>
            <RadioGroup
              defaultValue=""
              value={queryParams.pets}
              onValueChange={(value) => {
                setQueryParams((prev: any) => ({
                  ...prev,
                  pets: value,
                }));
              }}
            >
              <div className="flex items-center space-x-2">
                <RadioGroupItem value="no_pets" id="none" />
                <Label htmlFor="none">No pets</Label>
              </div>
              <div className="flex items-center space-x-2">
                <RadioGroupItem value="cats" id="cats" />
                <Label htmlFor="cats">cats</Label>
              </div>
              <div className="flex items-center space-x-2">
                <RadioGroupItem value="dogs" id="dogs" />
                <Label htmlFor="dogs">Dogs</Label>
              </div>
              <div className="flex items-center space-x-2">
                <RadioGroupItem value="any" id="any" />
                <Label htmlFor="any">Any pet</Label>
              </div>
            </RadioGroup>
          </div>
          <div className="flex flex-col gap-2">
            <p>Smoking Habbits</p>
            <RadioGroup
              defaultValue=""
              value={queryParams.smoking}
              onValueChange={(value) => {
                setQueryParams((prev: any) => ({
                  ...prev,
                  smoking: value,
                }));
              }}
            >
              <div className="flex items-center space-x-2">
                <RadioGroupItem value="non_smoker" id="non_smoker" />
                <Label htmlFor="non_smoker">Non smoker</Label>
              </div>
              <div className="flex items-center space-x-2">
                <RadioGroupItem value="ocassional" id="ocassional" />
                <Label htmlFor="ocassional">Ocassional Smoker</Label>
              </div>
              <div className="flex items-center space-x-2">
                <RadioGroupItem value="smoker" id="smoker" />
                <Label htmlFor="smoker">Smoker</Label>
              </div>
            </RadioGroup>
          </div>
          <div className="flex flex-col gap-2">
            <p>Cleaning Habbits</p>
            <div className="flex gap-3 items-center">
              <p className="text-sm">Relaxed</p>
              <Slider
                showValues={false}
                value={[queryParams.cleaning]}
                max={2}
                step={1}
                onValueChange={(value) => {
                  setQueryParams((prev: any) => ({
                    ...prev,
                    cleaning: value[0],
                  }));
                }}
              />
              <p className="text-sm text-nowrap">Very tidy</p>
            </div>
          </div>
          <div className="flex flex-col gap-2">
            <p>Party Habbits</p>
            <div className="flex gap-3 items-center">
              <p className="text-sm text-center">Quiet evenings</p>
              <Slider
                showValues={false}
                value={[queryParams.party]}
                max={2}
                step={1}
                onValueChange={(value) => {
                  setQueryParams((prev: any) => ({
                    ...prev,
                    party: value[0],
                  }));
                }}
              />
              <p className="text-sm text-center">Hosting parties</p>
            </div>
          </div>
        </div>
      </SheetContent>
    </Sheet>
  );
};
