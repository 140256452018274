export const City = ({ className }: { className: string }) => {
    return(
<svg height="40px" width="40px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" 
	 viewBox="0 0 512 512" xmlSpace="preserve">
<g transform="translate(1 1)">
    <g>
        <polygon style={{ fill: '#FFA800' }} points="331.8,246.467 365.933,246.467 365.933,212.333 331.8,212.333 		"/>
        <polygon style={{ fill: '#FFA800' }} points="417.133,101.4 468.333,101.4 468.333,67.267 417.133,67.267 		"/>
        <polygon style={{ fill: '#FFA800' }} points="391.533,502.467 493.933,502.467 493.933,101.4 391.533,101.4 		"/>
    </g>
	<g>
		<polygon style={{ fill:"#FFE100;" }}  points="391.533,502.467 468.333,502.467 468.333,101.4 391.533,101.4 		"/>
		<polygon style={{ fill: '#FFE100' }} points="186.733,502.467 391.533,502.467 391.533,246.467 186.733,246.467 		"/>
	</g>
	<polygon style={{ fill: '#FFA800' }} points="33.133,297.667 84.333,297.667 84.333,263.533 33.133,263.533 	"/>
	<g>
		<polygon style={{ fill: '#FFE100' }} points="255,67.267 255,7.533 152.6,7.533 152.6,67.267 118.467,67.267 118.467,297.667 
			186.733,297.667 186.733,246.467 289.133,246.467 289.133,67.267 		"/>
		<polygon style={{ fill: '#FFE100' }} points="16.067,502.467 186.733,502.467 186.733,297.667 16.067,297.667 		"/>
	</g>
	<polygon style={{ fill: '#63D3FD' }} points="118.467,502.467 186.733,502.467 186.733,331.8 118.467,331.8 	"/>
	<path d="M186.733,511H16.067c-5.12,0-8.533-3.413-8.533-8.533v-204.8c0-5.12,3.413-8.533,8.533-8.533h170.667
		c5.12,0,8.533,3.413,8.533,8.533v204.8C195.267,507.587,191.853,511,186.733,511z M24.6,493.933h153.6V306.2H24.6V493.933z"/>
	<path d="M391.533,511h-204.8c-5.12,0-8.533-3.413-8.533-8.533v-256c0-5.12,3.413-8.533,8.533-8.533h204.8
		c5.12,0,8.533,3.413,8.533,8.533v256C400.067,507.587,396.653,511,391.533,511z M195.267,493.933H383V255H195.267V493.933z"/>
	<path d="M84.333,306.2h-51.2c-5.12,0-8.533-3.413-8.533-8.533v-34.133c0-5.12,3.413-8.533,8.533-8.533h51.2
		c5.12,0,8.533,3.413,8.533,8.533v34.133C92.867,302.787,89.453,306.2,84.333,306.2z M41.667,289.133H75.8v-17.067H41.667V289.133z"
		/>
	<path d="M186.733,511h-68.267c-5.12,0-8.533-3.413-8.533-8.533V331.8c0-5.12,3.413-8.533,8.533-8.533h68.267
		c5.12,0,8.533,3.413,8.533,8.533v170.667C195.267,507.587,191.853,511,186.733,511z M127,493.933h51.2v-153.6H127V493.933z"/>
	<path d="M220.867,511c-5.12,0-8.533-3.413-8.533-8.533V280.6c0-5.12,3.413-8.533,8.533-8.533c5.12,0,8.533,3.413,8.533,8.533
		v221.867C229.4,507.587,225.987,511,220.867,511z"/>
	<path d="M255,511c-5.12,0-8.533-3.413-8.533-8.533V280.6c0-5.12,3.413-8.533,8.533-8.533s8.533,3.413,8.533,8.533v221.867
		C263.533,507.587,260.12,511,255,511z"/>
	<path d="M289.133,511c-5.12,0-8.533-3.413-8.533-8.533V280.6c0-5.12,3.413-8.533,8.533-8.533c5.12,0,8.533,3.413,8.533,8.533
		v221.867C297.667,507.587,294.253,511,289.133,511z"/>
	<path d="M323.267,511c-5.12,0-8.533-3.413-8.533-8.533V280.6c0-5.12,3.413-8.533,8.533-8.533c5.12,0,8.533,3.413,8.533,8.533
		v221.867C331.8,507.587,328.387,511,323.267,511z"/>
	<path d="M357.4,511c-5.12,0-8.533-3.413-8.533-8.533V280.6c0-5.12,3.413-8.533,8.533-8.533s8.533,3.413,8.533,8.533v221.867
		C365.933,507.587,362.52,511,357.4,511z"/>
	<path d="M365.933,255H331.8c-5.12,0-8.533-3.413-8.533-8.533v-34.133c0-5.12,3.413-8.533,8.533-8.533h34.133
		c5.12,0,8.533,3.413,8.533,8.533v34.133C374.467,251.587,371.053,255,365.933,255z M340.333,237.933H357.4v-17.067h-17.067V237.933
		z"/>
	<path d="M493.933,511h-102.4c-5.12,0-8.533-3.413-8.533-8.533V101.4c0-5.12,3.413-8.533,8.533-8.533h102.4
		c5.12,0,8.533,3.413,8.533,8.533v401.067C502.467,507.587,499.053,511,493.933,511z M400.067,493.933H485.4v-384h-85.333V493.933z"
		/>
	<path d="M468.333,109.933h-51.2c-5.12,0-8.533-3.413-8.533-8.533V67.267c0-5.12,3.413-8.533,8.533-8.533h51.2
		c5.12,0,8.533,3.413,8.533,8.533V101.4C476.867,106.52,473.453,109.933,468.333,109.933z M425.667,92.867H459.8V75.8h-34.133
		V92.867z"/>
	<path d="M186.733,374.467H152.6c-5.12,0-8.533-3.413-8.533-8.533s3.413-8.533,8.533-8.533h34.133c5.12,0,8.533,3.413,8.533,8.533
		S191.853,374.467,186.733,374.467z"/>
	<path d="M186.733,408.6H152.6c-5.12,0-8.533-3.413-8.533-8.533s3.413-8.533,8.533-8.533h34.133c5.12,0,8.533,3.413,8.533,8.533
		S191.853,408.6,186.733,408.6z"/>
	<path d="M186.733,442.733H152.6c-5.12,0-8.533-3.413-8.533-8.533c0-5.12,3.413-8.533,8.533-8.533h34.133
		c5.12,0,8.533,3.413,8.533,8.533C195.267,439.32,191.853,442.733,186.733,442.733z"/>
	<path d="M186.733,476.867H152.6c-5.12,0-8.533-3.413-8.533-8.533c0-5.12,3.413-8.533,8.533-8.533h34.133
		c5.12,0,8.533,3.413,8.533,8.533C195.267,473.453,191.853,476.867,186.733,476.867z"/>
	<path d="M348.867,220.867c-5.12,0-8.533-3.413-8.533-8.533v-17.067c0-5.12,3.413-8.533,8.533-8.533c5.12,0,8.533,3.413,8.533,8.533
		v17.067C357.4,217.453,353.987,220.867,348.867,220.867z"/>
	<path d="M152.6,306.2c-5.12,0-8.533-3.413-8.533-8.533V101.4c0-5.12,3.413-8.533,8.533-8.533c5.12,0,8.533,3.413,8.533,8.533
		v196.267C161.133,302.787,157.72,306.2,152.6,306.2z"/>
	<path d="M186.733,255c-5.12,0-8.533-3.413-8.533-8.533V101.4c0-5.12,3.413-8.533,8.533-8.533c5.12,0,8.533,3.413,8.533,8.533
		v145.067C195.267,251.587,191.853,255,186.733,255z"/>
	<path d="M220.867,255c-5.12,0-8.533-3.413-8.533-8.533V101.4c0-5.12,3.413-8.533,8.533-8.533c5.12,0,8.533,3.413,8.533,8.533
		v145.067C229.4,251.587,225.987,255,220.867,255z"/>
	<path d="M255,255c-5.12,0-8.533-3.413-8.533-8.533V101.4c0-5.12,3.413-8.533,8.533-8.533s8.533,3.413,8.533,8.533v145.067
		C263.533,251.587,260.12,255,255,255z"/>
	<path d="M425.667,470.04c-5.12,0-8.533-3.413-8.533-8.533V444.44c0-5.12,3.413-8.533,8.533-8.533s8.533,3.413,8.533,8.533v17.067
		C434.2,465.773,430.787,470.04,425.667,470.04z M425.667,418.84c-5.12,0-8.533-3.413-8.533-8.533V393.24
		c0-5.12,3.413-8.533,8.533-8.533s8.533,3.413,8.533,8.533v17.067C434.2,414.573,430.787,418.84,425.667,418.84z M425.667,367.64
		c-5.12,0-8.533-3.413-8.533-8.533V342.04c0-5.12,3.413-8.533,8.533-8.533s8.533,3.413,8.533,8.533v17.067
		C434.2,363.373,430.787,367.64,425.667,367.64z M425.667,316.44c-5.12,0-8.533-3.413-8.533-8.533V290.84
		c0-5.12,3.413-8.533,8.533-8.533s8.533,3.413,8.533,8.533v17.067C434.2,312.173,430.787,316.44,425.667,316.44z M425.667,265.24
		c-5.12,0-8.533-3.413-8.533-8.533V239.64c0-5.12,3.413-8.533,8.533-8.533s8.533,3.413,8.533,8.533v17.067
		C434.2,260.973,430.787,265.24,425.667,265.24z M425.667,214.04c-5.12,0-8.533-3.413-8.533-8.533V188.44
		c0-5.12,3.413-8.533,8.533-8.533s8.533,3.413,8.533,8.533v17.067C434.2,209.773,430.787,214.04,425.667,214.04z M425.667,162.84
		c-5.12,0-8.533-3.413-8.533-8.533V137.24c0-5.12,3.413-8.533,8.533-8.533s8.533,3.413,8.533,8.533v17.067
		C434.2,158.573,430.787,162.84,425.667,162.84z"/>
	<path d="M459.8,470.04c-5.12,0-8.533-3.413-8.533-8.533V444.44c0-5.12,3.413-8.533,8.533-8.533s8.533,3.413,8.533,8.533v17.067
		C468.333,465.773,464.92,470.04,459.8,470.04z M459.8,418.84c-5.12,0-8.533-3.413-8.533-8.533V393.24
		c0-5.12,3.413-8.533,8.533-8.533s8.533,3.413,8.533,8.533v17.067C468.333,414.573,464.92,418.84,459.8,418.84z M459.8,367.64
		c-5.12,0-8.533-3.413-8.533-8.533V342.04c0-5.12,3.413-8.533,8.533-8.533s8.533,3.413,8.533,8.533v17.067
		C468.333,363.373,464.92,367.64,459.8,367.64z M459.8,316.44c-5.12,0-8.533-3.413-8.533-8.533V290.84
		c0-5.12,3.413-8.533,8.533-8.533s8.533,3.413,8.533,8.533v17.067C468.333,312.173,464.92,316.44,459.8,316.44z M459.8,265.24
		c-5.12,0-8.533-3.413-8.533-8.533V239.64c0-5.12,3.413-8.533,8.533-8.533s8.533,3.413,8.533,8.533v17.067
		C468.333,260.973,464.92,265.24,459.8,265.24z M459.8,214.04c-5.12,0-8.533-3.413-8.533-8.533V188.44
		c0-5.12,3.413-8.533,8.533-8.533s8.533,3.413,8.533,8.533v17.067C468.333,209.773,464.92,214.04,459.8,214.04z M459.8,162.84
		c-5.12,0-8.533-3.413-8.533-8.533V137.24c0-5.12,3.413-8.533,8.533-8.533s8.533,3.413,8.533,8.533v17.067
		C468.333,158.573,464.92,162.84,459.8,162.84z"/>
	<path d="M50.2,459.8c-5.12,0-8.533-3.413-8.533-8.533V434.2c0-5.12,3.413-8.533,8.533-8.533s8.533,3.413,8.533,8.533v17.067
		C58.733,456.387,55.32,459.8,50.2,459.8z M50.2,408.6c-5.12,0-8.533-3.413-8.533-8.533V383c0-5.12,3.413-8.533,8.533-8.533
		s8.533,3.413,8.533,8.533v17.067C58.733,405.187,55.32,408.6,50.2,408.6z M50.2,357.4c-5.12,0-8.533-3.413-8.533-8.533V331.8
		c0-5.12,3.413-8.533,8.533-8.533s8.533,3.413,8.533,8.533v17.067C58.733,353.987,55.32,357.4,50.2,357.4z"/>
	<path d="M84.333,459.8c-5.12,0-8.533-3.413-8.533-8.533V434.2c0-5.12,3.413-8.533,8.533-8.533c5.12,0,8.533,3.413,8.533,8.533
		v17.067C92.867,456.387,89.453,459.8,84.333,459.8z M84.333,408.6c-5.12,0-8.533-3.413-8.533-8.533V383
		c0-5.12,3.413-8.533,8.533-8.533c5.12,0,8.533,3.413,8.533,8.533v17.067C92.867,405.187,89.453,408.6,84.333,408.6z M84.333,357.4
		c-5.12,0-8.533-3.413-8.533-8.533V331.8c0-5.12,3.413-8.533,8.533-8.533c5.12,0,8.533,3.413,8.533,8.533v17.067
		C92.867,353.987,89.453,357.4,84.333,357.4z"/>
	<path d="M186.733,306.2h-68.267c-5.12,0-8.533-3.413-8.533-8.533v-230.4c0-5.12,3.413-8.533,8.533-8.533h25.6v-51.2
		c0-5.12,3.413-8.533,8.533-8.533H255c5.12,0,8.533,3.413,8.533,8.533v51.2h25.6c5.12,0,8.533,3.413,8.533,8.533v179.2
		c0,5.12-3.413,8.533-8.533,8.533h-93.867v42.667C195.267,302.787,191.853,306.2,186.733,306.2z M127,289.133h51.2v-42.667
		c0-5.12,3.413-8.533,8.533-8.533H280.6V75.8H255c-5.12,0-8.533-3.413-8.533-8.533v-51.2h-85.333v51.2
		c0,5.12-3.413,8.533-8.533,8.533H127V289.133z"/>
	<path d="M186.733,75.8c-5.12,0-8.533-3.413-8.533-8.533v-25.6c0-5.12,3.413-8.533,8.533-8.533c5.12,0,8.533,3.413,8.533,8.533v25.6
		C195.267,72.387,191.853,75.8,186.733,75.8z"/>
	<path d="M220.867,75.8c-5.12,0-8.533-3.413-8.533-8.533v-25.6c0-5.12,3.413-8.533,8.533-8.533c5.12,0,8.533,3.413,8.533,8.533v25.6
		C229.4,72.387,225.987,75.8,220.867,75.8z"/>
</g>
</svg>
    );
};