import { instance } from "../../config/intercepter";

export const getCandidateProfiles = async ({
  queryKey,
}: {
  queryKey: [string, { prof_liked: string, gender: string, move_in_date: string, max_rent: number, pets: string, age: Array<number>}];
}) => {
  const [_key, { prof_liked, gender, move_in_date, max_rent, pets, age}] = queryKey;

  const response = await instance.get(
    `/user/get-candidate-profiles?prof_liked=${prof_liked}&gender=${gender}&move_in_date=${move_in_date}&max_rent=${max_rent}&pets=${pets}&age_min=${age[0]}&age_max=${age[1]}`,
  );

  return response.data;
};

export const getSingleCandidateProfile = async ({
  queryKey,
}: {
  queryKey: [string, { user_id: string }];
}) => {
  const [_key, { user_id }] = queryKey;

  const response = await instance.get(`/user/get-candidate-profile/${user_id}`);

  return response.data;
};
