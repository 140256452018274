import { useMutation, useQuery } from "@tanstack/react-query";
import {
  getChatsForUser,
  getChatData,
  sendMessage
} from "../endpoints/chat";


export const useGetChatsForUser = ({
    user_id,
  }: {
    user_id: string;
  }) => {
    return useQuery({
      queryKey: ["getChatsForUser", { user_id }],
      queryFn: getChatsForUser,
      staleTime: 0,
      retry: true,
      enabled: user_id !== undefined || user_id !== ''
    });
  };

  export const useGetChatData = ({
    chat_id,
  }: {
    chat_id: string;
  }) => {
    return useQuery({
      queryKey: ["getChatData", { chat_id }],
      queryFn: getChatData,
      staleTime: 0,
      retry: true,
      enabled: chat_id !== undefined && chat_id !== '' 
    });
  };

  export const useSendMessage = () => {
    return useMutation({
      mutationFn: (payload: {chat_id: string, message_text:string}) => sendMessage(payload.chat_id, payload.message_text),
    });
  };