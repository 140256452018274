import { useState } from "react";
import { useDropzone } from "react-dropzone";
import { AddImage } from "../../icons/addImage";

export const Upload = ({
  maxFiles,
  handleDrop,
}: {
  maxFiles: number;
  handleDrop: (value: File) => void;
}) => {
  const [uploadedFile, setUploadedFile] = useState<File | null>(null);

  const onDrop = (acceptedFiles: any) => {
    setUploadedFile(acceptedFiles[0]);
    handleDrop(acceptedFiles[0]);
    console.log(acceptedFiles[0])
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: { "image/*": [] },
    onDrop,
    maxFiles: maxFiles,
  });

  return (
    <div {...getRootProps()} className="flex items-center justify-center shadow-lg p-4 w-32 h-32 rounded-sm cursor-pointer m-2">
      <input {...getInputProps()} />
      {uploadedFile ? (
        <p className="text-green-500">Uploaded: {uploadedFile.name}</p>
      ) : (
        <AddImage />
      )}
    </div>
  );
};