import { instance } from "../../config/intercepter";

export const getChatsForUser = async ({
    queryKey,
  }: {
    queryKey: [string, { user_id: string }];
  }) => {
    const [_key, { user_id }] = queryKey;

    console.log("[QUERY] getChatsForUser");    
    const response = await instance.get(`/chat/chats-for-user/`);
  
    return response.data;
  };

  export const getChatData = async ({
    queryKey,
  }: {
    queryKey: [string, { chat_id: string }];
  }) => {
    const [_key, { chat_id }] = queryKey;
    
    console.log("[QUERY] getChatData");
    const response = await instance.get(`/chat/get-chat-data/${chat_id}`);
  
    return response.data;
  };

  export const sendMessage = async (chat_id: string, message_text: string) => {
    const payload = {
      message_text: message_text,
      chat_id: chat_id
    };
    
    console.log("[QUERY] sendMessage");
    const response = await instance.post(`/chat/message/`, payload);
    return response.data;
  };