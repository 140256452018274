import { useEffect, useState } from "react";
import { useGetUserInfo } from "../api/hooks/user";
import { CardInfo } from "../../types/cardInfo";
import { Male } from "../icons/male";
import { Female } from "../icons/female";
import { Other } from "../icons/other";
import { Work } from "../icons/work";
import { Study } from "../icons/study";
import { useNavigate } from "react-router-dom";
import { useUserStore } from "../store/userStore";
import { UserInfo } from "../../types/userInfo";

export const DashboardPage = () => {
  const [user, setUser] = useState<CardInfo | null>(null);
  const [userInfo, setUserInfo] = useState<UserInfo | null>(null);
  const { data: userData, isLoading: userInfoLoading } = useGetUserInfo();
  const { userInfo: user_info } = useUserStore();
  const navigate = useNavigate();

  console.log(typeof user?.regions)
  useEffect(()=>{
    if(userData){
      setUser(userData);
    } else {
      setUser(null);
    }
  }, [userData])


  useEffect(()=>{
    if(user_info){
      setUserInfo(user_info)
    } else {
      setUserInfo(null);
    }
  }, [user_info])

  const capitalize = (str: string) => {
    if (!str) return '';
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  };

  const calculateAge = (dateOfBirth: string): number => {
    const today = new Date(); 
    const birthDate = new Date(dateOfBirth); 
    let age = today.getFullYear() - birthDate.getFullYear(); 

    const monthDiff = today.getMonth() - birthDate.getMonth();
    const dayDiff = today.getDate() - birthDate.getDate();
    
    if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) {
      age--;
    }
  
    return age;
  };

  const formatDate = (isoString: string): string => {
    const date = new Date(isoString);
    const day = date.getDate();
    const month = date.toLocaleString('en-US', { month: 'long' }); 
    const year = date.getFullYear();
  
    return `${day} ${month} ${year}`;
  };
  

  return (
    <div className="flex flex-row pt-5">
      <div className="flex flex-col w-1/3 bg-white shadow-md justify-center rounded-md">
        {/* <span className="font-medium text-[2rem] leading-[3.5rem] p-5">  </span>   */}
        <h1 className="text-5xl font-normal dark:text-white p-5">My profile</h1>
        <div className="w-[90%] bg-gray-200 rounded-full dark:bg-gray-700 self-center">
        {userInfo?.onboarding_step && (
          <div className="bg-gray-200 rounded-full h-4 w-full">
            <div
              className="bg-[#FFC000] text-xs font-medium text-[#26355D] text-center p-0.5 leading-none rounded-full"
              style={{ width: `${(userInfo.onboarding_step / 3) * 100}%` }}
            >
              {`${Math.round((userInfo.onboarding_step / 3) * 100)}%`}
            </div>
          </div>
        )}
        </div>
        {/* <img className="w-full object-contain p-5" src="https://i.pravatar.cc/150" alt="Profile Image"></img> */}
        <img className="w-full object-contain p-5" src={user?.img_url} alt="Profile Image"></img>
        <button type="button"
          onClick={() => navigate("/user-form")}
          className="text-white bg-gradient-to-br from-[#f85a20] to-[#FFC000] hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-[#f85a20] dark:focus:ring-[#f85a20] font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 w-3/4 self-center">Edit profile</button>
        {user && <>
         
          <div className="flex flex-row flex-wrap mt-2">
            {user.gender === 'male' ? (
                <div className="flex flex-row ml-5">
                  <Male className={ user.gender === "male" ? "border rounded-full" : ""}/>
                  <p className="p-2 self-center">Male</p>
                </div>
              ) : user.gender === 'female' ? (
                <div className="flex flex-row ml-5">
                  <Female className={ user.gender === "female" ? "border rounded-full" : ""}/>
                  <p className="p-2 self-center">Female</p>
                </div>
              ) : (
                <div className="flex flex-row ml-5">
                  <Other className={ user.gender === "other" ? "border rounded-full" : ""}/>
                  <p className="p-2 self-center">Other</p>
                </div>
            )}
            {user.occupation === "work"? (
              <div className="flex flex-row ml-5">
                <Work className={ user.occupation === "work" ? "border rounded-full" : "" }/>
                <p className="p-2 self-center">Work</p>
              </div>
            ) : (
              <div className="flex flex-row ml-5">
                <Study className={ user.occupation === "study" ? "border rounded-full" : "" }/>
                <p className="p-2 self-center">Study</p>
              </div>
            )}
          </div>
          <span className="font-normal text-[2rem] leading-[3.5rem] ml-5 pt-2"> {[capitalize(user.first_name), ", ",  calculateAge(user.date_of_birth)]}</span>
          <div className="flex flex-row flex-wrap ml-5 mt-2 mb-2">
            {/* <span>Lifestyle: </span> */}
            <span className="bg-[#FFC000] text-[#26355D] text-xs font-medium me-2 px-2.5 py-0.5 rounded-full dark:bg-blue-900 dark:text-blue-300 mt-1 mb-1">
              {user.allow_smoking === true ? ("Smoker") : ("Non-Smoker")}
            </span>
            <span className="bg-[#FFC000] text-[#26355D] text-xs font-medium me-2 px-2.5 py-0.5 rounded-full dark:bg-blue-900 dark:text-blue-300 mt-1 mb-1">
              {user.allow_pet === true ? ("Allow Pets") : ("Not Allow Pets")}
            </span>
          </div>
          <div className="p-4 text-sm text-gray-800 rounded-lg bg-gray-50 dark:bg-gray-800 dark:text-gray-300" role="alert">
            <span className="font-normal"> Member since</span> {formatDate(user.created_at)}
          </div>
        </>}


      </div>


      <div className="flex flex-col flex-start items-center w-2/3 bg-white shadow-md rounded-md ml-2">
      {userInfo?.status === "USER_PARTIALLY_ONBOARDED" && (
        <div className="bg-yellow rounded-full w-fit p-2 text-sm">
          <p>
            <button
              onClick={() => {
                navigate("/user-form");
              }}
              className="underline"
            >
              Complete your profile
            </button>{" "}
            to get better, more personalized matches!
          </p>
        </div>
      )}
        <div className="w-[90%] flex flex-col p-6 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 m-5">
          <h5 className="mb-2 text-2xl font-normal tracking-tight text-gray-900 dark:text-white">Introduction</h5>
            <div className="flex flex-col flex-wrap pl-5">
                <p className="font-normal text-gray-700 dark:text-gray-400"> {user?.introduce_yourself}</p>
            </div>
        </div>
        <div className="w-[90%] flex flex-col p-6 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
          <h5 className="mb-2 text-2xl font-normal tracking-tight text-gray-900 dark:text-white">Preferences</h5>
            <div className="flex flex-row flex-wrap pl-5">
              <h6 className="text-md font-normal dark:text-white">City:</h6>
              <p className="font-normal text-gray-700 dark:text-gray-400 self-center pl-2"> {user?.city_of_interest}</p>
            </div>
            <div className="flex flex-row flex-wrap pl-5">
              <h6 className="text-md font-normal dark:text-white">Regions:</h6>
              {user?.regions &&
                Object.values(user.regions).map((region, index) => (
                  <p
                    key={index}
                    className="font-normal text-gray-700 dark:text-gray-400 self-center pl-2"
                  >
                    {region}
                  </p>
              ))}
            </div>
            <div className="flex flex-row flex-wrap pl-5">
              <h6 className="text-md font-normal dark:text-white">Move-in Date:</h6>
              <p className="font-normal text-gray-700 dark:text-gray-400 self-center pl-2"> {user?.preferred_move_in_date}</p>
            </div>
            <div className="flex flex-row flex-wrap pl-5">
              <h6 className="text-md font-normal dark:text-white">Budget:</h6>
              <p className="font-normal text-gray-700 dark:text-gray-400 self-center pl-2"> {user?.budget_max}</p>
            </div>
            {/* <div className="flex flex-row flex-wrap pl-5">
              <h6 className="text-md font-normal dark:text-white">Cleaning Habits:</h6>
              <p className="font-normal text-gray-700 dark:text-gray-400 self-center pl-2"> {user?.}</p>
            </div> */}
        </div>
        <div className="w-[90%] flex flex-col p-6 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 mt-5">
          <h5 className="mb-2 text-2xl font-normal tracking-tight text-gray-900 dark:text-white">About yourself</h5>
          <div className="flex flex-col flex-wrap pl-5">
              <h6 className="text-md font-normal dark:text-white">Hobbies</h6>
              <p className="font-normal text-gray-700 dark:text-gray-400 pl-2"> {user?.hobbies}</p>
          </div>
          <div className="flex flex-col flex-wrap pl-5">
              <h6 className="text-md font-normal dark:text-white">Music</h6>
              <p className="font-normal text-gray-700 dark:text-gray-400 pl-2"> {user?.music_preferred}</p>
          </div>
      </div>
      </div>
    </div>
  )
};
