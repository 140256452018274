import { useRef, useState } from "react";
import { useSendMessage } from "../../api/hooks/useChat";
import { queryClient } from "../../api/queryClient";

const MAX_HEIGHT = 200;

export  const SendBar =({chat_id} : {chat_id:string}) => {
    const [messageText, setMessageText] = useState("");
    const [lastSentMessage, setLastSentMessage] = useState("");
    const textareaRef = useRef<HTMLTextAreaElement>(null);

    // This is only if we want the attachments
    const fileInputRef = useRef<HTMLInputElement | null>(null);
    const [fileInput, setFileInput] = useState<File | null>(null);
    
    const handleImageAttachClick = () => {
      if (fileInputRef.current) {
        fileInputRef.current.click(); // Trigger the file input click
      }
    }
    
    const {mutate: sendMessage} = useSendMessage()

    const adjustTextareaHeight = () => {
        if (textareaRef.current) {
          textareaRef.current.style.height = "auto";
          const newHeight = Math.min(textareaRef.current.scrollHeight, MAX_HEIGHT);
          textareaRef.current.style.height = `${newHeight}px`;
        }
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      setMessageText(e.target.value);
      adjustTextareaHeight();
    };
  
    // TODO: Add in messages attachments field. And add to the message the attachment files
    const handleSendMessage = (message: string) => {
      if (message.trim() !== "") {
        console.log("User message:", message);
        setLastSentMessage(message);
        const message_text = message
        setMessageText("");
        adjustTextareaHeight();
        const payload = {chat_id, message_text}
        sendMessage(payload, {
            onSuccess: (data) => {
              queryClient.invalidateQueries({queryKey: ["getChatData"],});
            },
          })
      }
    };
  
    const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      handleSendMessage(messageText);
    };
  
    const handleKeyUp = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
      if (e.key === "Enter" && !e.shiftKey) {
        e.preventDefault();
        handleSendMessage(messageText);
      }
    };
    
    return(
        <div className="ml-2 mr-2 shadow-md w-full bg-gray-50 dark:bg-gray-700">
        <form onSubmit={handleFormSubmit}>
            <label htmlFor="chat" className="sr-only">Your message</label>
            <div className="flex items-center px-3 py-2 rounded-lg bg-gray-50 dark:bg-gray-700">
                
                {/* This is for file attachments */}
                {/* <button 
                  type="button" 
                  className="inline-flex justify-center p-2 text-gray-500 rounded-lg cursor-pointer hover:text-gray-900 hover:bg-gray-100 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-600"
                  onClick={handleImageAttachClick}
                >
                   <input
                      type="file"
                      ref={fileInputRef}
                      accept=".jpg,.png,.jpeg"
                      onChange={(e) => {
                        if (e.target.files && e.target.files.length > 0) {
                          setFileInput(e.target.files[0]);
                        }
                      }}
                      style={{ display: 'none' }}
                    />
                    <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 18">
                        <path fill="currentColor" d="M13 5.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0ZM7.565 7.423 4.5 14h11.518l-2.516-3.71L11 13 7.565 7.423Z"/>
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M18 1H2a1 1 0 0 0-1 1v14a1 1 0 0 0 1 1h16a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1Z"/>
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 5.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0ZM7.565 7.423 4.5 14h11.518l-2.516-3.71L11 13 7.565 7.423Z"/>
                    </svg>
                    <span className="sr-only">Upload image</span>
                </button>
                 */}
                <textarea 
                    id="chat" 
                    ref={textareaRef}
                    rows={1} 
                    value={messageText}
                    onChange={handleInputChange}
                    onKeyUp={handleKeyUp}
                    className="block mx-4 p-2.5 w-full text-sm text-gray-900 bg-white rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-800 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" 
                    placeholder="Your message...">
                </textarea>
                
                <button type="submit" className="inline-flex justify-center p-2 text-blue-600 rounded-full cursor-pointer hover:bg-blue-100 dark:text-blue-500 dark:hover:bg-gray-600">
                    <svg className="w-5 h-5 rotate-90 rtl:-rotate-90" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 20">
                        <path d="m17.914 18.594-8-18a1 1 0 0 0-1.828 0l-8 18a1 1 0 0 0 1.157 1.376L8 18.281V9a1 1 0 0 1 2 0v9.281l6.758 1.689a1 1 0 0 0 1.156-1.376Z"/>
                    </svg>
                    <span className="sr-only">Send message</span>
                </button>
            </div>
        </form>
        </div>
    )
}