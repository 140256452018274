import { Route, Routes } from "react-router-dom";
import { Layout } from "./layouts/layout";
import { FindMatchesPage } from "./pages/findMatchesPage";
import { ProtectedRoute } from "./utils/protectedRoute";
import { UserFormPage } from "./pages/userFormPage";
import { DashboardPage } from "./pages/dashboardPage";
import { LoginPage } from "./pages/loginPage";
import { ChatPage } from "./pages/chatPage";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route path="/login" element={<LoginPage />} />
        <Route
          path="/"
          element={<ProtectedRoute Component={<DashboardPage />} />}
        />
        <Route
          path="/user-form"
          element={<ProtectedRoute Component={<UserFormPage />} />}
        />
        <Route
          path="/find-matches"
          element={<ProtectedRoute Component={<FindMatchesPage />} />}
        />
        <Route
          path="/chat"
          element={<ProtectedRoute Component={<ChatPage />} />}
        />
        {/*
        <Route path="/user-profile" />
        <Route path="/prices" /> */}
      </Route>
    </Routes>
  );
}

export default App;
