import { useEffect, useState } from "react";
import { useGetCandidateProfiles } from "../api/hooks/useCandidates";
import { ListCard } from "../components/roomates/listCard";
import { Loader } from "../components/ui/loader";
import { CardInfo } from "../../types/cardInfo";
import {
  useCreateUserPair,
  useDeleteUserPair,
  useGetCurrentPairs,
} from "../api/hooks/user";
import { RoomatesFilters } from "../components/roomates/roomatesFilter";
import { queryClient } from "../api/queryClient";
import { useUserStore } from "../store/userStore";
import { useNavigate } from "react-router-dom";

export const FindMatchesPage = () => {
  const [profiles, setProfiles] = useState<CardInfo[]>([]);

  const [queryParams, setQueryParams] = useState({
    prof_liked: "all",
    age: [18, 100],
    gender: "any",
    move_in_date: "",
    custom_move_in_date: null,
    max_rent: 2000,
    utilities: false,
    preferred_location: "",
    areas_near_by: false,
    pets: "",
    cleaning: null,
    smoking: "",
    party: null,
  });

  const { userInfo } = useUserStore();

  const { data: profilesData, isLoading: profilesLoading } =
    useGetCandidateProfiles(queryParams);

  const { data: currentPairs } = useGetCurrentPairs();
  const { mutate: deletePair } = useDeleteUserPair();
  const { mutate: createPair } = useCreateUserPair();

  const navigate = useNavigate();

  const handleFavouriteClicked = (value: boolean, id: string) => {
    if (value) {
      createPair(id, {
        onSuccess: (data) => {
          queryClient.invalidateQueries({
            queryKey: ["getPairs"],
          });
          queryClient.invalidateQueries({
            queryKey: ["getCandidates"],
          });
        },
      });
    } else {
      deletePair(id, {
        onSuccess: (data) => {
          queryClient.invalidateQueries({
            queryKey: ["getPairs"],
          });
          queryClient.invalidateQueries({
            queryKey: ["getCandidates"],
          });
        },
      });
    }
  };

  useEffect(() => {
    if (profilesData && currentPairs) {
      setProfiles(profilesData.profiles);
    } else {
      setProfiles([]);
    }
  }, [profilesData, currentPairs]);

  return (
    <div className="flex flex-col pt-5 gap-4">
      {userInfo?.status === "USER_PARTIALLY_ONBOARDED" && (
        <div className="bg-yellow rounded-full w-fit p-2 text-sm">
          <p>
            <button
              onClick={() => {
                navigate("/user-form");
              }}
              className="underline"
            >
              Complete your profile
            </button>{" "}
            to get better, more personalized matches!
          </p>
        </div>
      )}
      <div className="text-2xl flex justify-center">
        Find your ideal &nbsp; <span className="text-[#F85A20]">Roomie</span>
        &nbsp; today
      </div>
      <div className="flex gap-2">
        <RoomatesFilters
          setQueryParams={setQueryParams}
          queryParams={queryParams}
        />
      </div>
      <div className="px-10 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 pb-10 ">
        {profilesLoading && <Loader />}
        {profiles &&
          profiles.map((item) => (
            <ListCard
              key={item.id}
              cardInfo={item}
              isFavourite={
                currentPairs!.find((liked) => item.id === liked) !== undefined
              }
              favouriteClicked={handleFavouriteClicked}
            />
          ))}
      </div>
    </div>
  );
};
