import { useEffect, useState } from "react";
import { Stepper } from "../components/ui/stepper";
import { useUserStore } from "../store/userStore";
import { FormStepOne } from "../components/form/formStepOne";
import { UserDetails } from "../../types/userInfo";
import { useUpdateUser } from "../api/hooks/user";
import { useNavigate } from "react-router-dom";
import { FormStepTwo } from "../components/form/formStepTwo";
import { FormStepThree } from "../components/form/formStepThree";
import { useUploadImage, useRegisterImageAsDoc } from "../api/hooks/useImages";
import { useAuth0 } from "@auth0/auth0-react";

export const UserFormPage = () => {
  const { userInfo } = useUserStore();
  const [currentStep, setCurrentStep] = useState(1);
  const [presignedData, setPresignedData] = useState(null);

  const { mutate } = useUpdateUser();
  const { mutate: uploadImage } = useUploadImage();
  const {mutate: getPresignedUrl} = useRegisterImageAsDoc()

  const navigate = useNavigate();

  useEffect(() => {
    if (userInfo) {
      if (userInfo.onboarding_step === null) {
        setCurrentStep(1);
      } else {
        setCurrentStep(userInfo.onboarding_step + 2);
      }
    }
  }, [userInfo]);

  const handleSubmit = (values: Partial<UserDetails>) => {
    let finalValues = { ...values, onboarding_step: currentStep - 1 };

    if (currentStep === 1){
        getPresignedUrl(
        {filename: values.profile_image["name"], doc_type: "PRF" }, {
            onSuccess: (data) => {
                uploadImage(({data, file_to_upload: values.profile_image}), {
                        onSuccess: (upload_data) => { console.log('Image uploaded'); }
                    })
                finalValues.profile_image =  data["local_file_id"];
                mutate(finalValues, {
                      onSuccess: (data) => {
                          console.log(finalValues);
                          window.location.reload();
                        }
                    });

            },
        }
        )
    }

    mutate(finalValues, {
      onSuccess: (data) => {
          console.log(finalValues);
          if (currentStep !== 3) window.location.reload();
          else navigate("/");
        }
    });
  };

  const handleBack = () => {
    if (currentStep > 1) {
      const previousStep = currentStep - 1;
      setCurrentStep(previousStep);
      mutate({ onboarding_step: previousStep - 1 }, {
        onSuccess: (data) => {
          console.log(`Moved back to step ${previousStep}`);
        },
      });
    }
  };

  return (
    <div className="flex flex-col gap-4">
      <div className="text-2xl flex justify-center pt-4">
        Create your &nbsp; <span className="text-[#F85A20]">Meetie</span>
        &nbsp; profile
      </div>
      <Stepper totalSteps={3} currentStep={currentStep} />
      {currentStep === 1 && <FormStepOne handleSubmit={handleSubmit} />}
      {currentStep === 2 && <FormStepTwo handleSubmit={handleSubmit} handleBack={handleBack} />}
      {currentStep === 3 && <FormStepThree handleSubmit={handleSubmit} handleBack={handleBack} />}
    </div>
  );
};

export type UserInfo = {
  onboarding_step: number | null;
  status: string;
};
//
// export type UserDetails = {
//   first_name: string;
//   last_name: string;
//   email: string;
//   gender: string;
//   occupation: string;
//   date_of_birth: string;
//   profile_image: any;
//   city_of_interest: string;
//   regions: string[] | null | undefined; // Allow null or undefined
//   budget_max: number;
//   phone_number: string;
//   preferred_move_in_date: string | null | undefined; // Allow null or undefined
//   is_smoker: boolean | null | undefined; // Allow null or undefined
//   has_pets: boolean | null | undefined; // Allow null or undefined
//   cleaning_habits: string | null | undefined; // Allow null or undefined
//   introduce_yourself: string;
//   hobbies: string;
//   music_preferred: string;
//   movies_preferred: string;
//   onboarding_step: number;
// };