import { Heart, MapPin, Wallet } from "lucide-react";
import { UserIcon } from "../../icons/userIcon";
import { CardInfo } from "../../../types/cardInfo";
import { Dialog, DialogContent, DialogTrigger } from "../ui/dialog";
import { RoomateCard } from "./roomateCard";

export const ListCard = ({
  cardInfo,
  isFavourite,
  favouriteClicked,
}: {
  cardInfo: CardInfo;
  isFavourite: boolean;
  favouriteClicked: (value: boolean, id: string) => void;
}) => {
  const calculateAge = (birthdate: string): number => {
    const birthDate = new Date(birthdate);
    const today = new Date();

    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();
    const dayDifference = today.getDate() - birthDate.getDate();

    // Adjust age if birth month hasn't occurred yet this year or if it's the current month but the birth date hasn't happened yet
    if (monthDifference < 0 || (monthDifference === 0 && dayDifference < 0)) {
      age--;
    }

    return age;
  };

  return (
    <Dialog>
      <DialogTrigger asChild>
        <div className="rounded-md shadow-md p-4 flex flex-col gap-2 items-center w-fit hover:cursor-pointer">
          <UserIcon imageUrl={cardInfo.img_url}/>
          <div className="flex gap-2">
            <p>
              {cardInfo.first_name} {cardInfo.last_name},{" "}
              {calculateAge(cardInfo.date_of_birth)}
            </p>
            <button
              onClick={(e) => {
                e.stopPropagation();
                favouriteClicked(!isFavourite, cardInfo.id);
              }}
            >
              {isFavourite ? (
                <Heart className="fill-orange text-orange" />
              ) : (
                <Heart />
              )}
            </button>
          </div>
          <div className="flex justify-between">
            <div className="flex w-[150px] items-center gap-1">
              <MapPin className="fill-white text-yellow" />
              <p>{cardInfo.city_of_interest}</p>
            </div>
            <div className="flex items-center gap-1">
              <Wallet className="fill-white text-yellow" />
              <p>{cardInfo.budget_max}€</p>
            </div>
          </div>
        </div>
      </DialogTrigger>
      <DialogContent>
        <RoomateCard cardInfo={cardInfo} isFavourite={isFavourite} favouriteClicked={favouriteClicked} />
      </DialogContent>
    </Dialog>
  );
};
