import { ChatListData, ChatListItem} from "../../../types/chatInfo";

type ChatListProps = {
  list?: ChatListData;
  handleChatListClick: (chatItem: ChatListItem) => void;
};

export const ChatList = ({ list, handleChatListClick }: ChatListProps) => {
  return (
    <ul role="list" className="max-w-sm divide-y divide-gray-200 dark:divide-gray-700 ">
      {list?.data?.length ? (
        list.data.map((item) => {
          console.log(item)
          return(
          <li
            key={item.id}
            className="cursor-pointer"
            onClick={() => handleChatListClick(item)}
          >
            <div className="flex items-center space-x-3 rtl:space-x-reverse m-1">
              <div className="flex-shrink-0">
                <img
                  className="w-8 h-8 rounded-full object-cover"
                  src={item.sender.avatar}
                  alt={`${item.sender.name}'s avatar`}
                />
              </div>
              <div className="flex-1 min-w-0">
                <p className="text-sm font-semibold text-gray-900 truncate dark:text-white">
                  {item.sender.name}
                </p>
                <p>Say hi!</p>
              </div>
              <span
                className={`hidden sm:inline-flex items-center text-xs font-medium px-2.5 py-0.5 rounded-full ${
                  item.sender.online
                    ? "bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-300"
                    : "bg-gray-100 text-gray-800 dark:bg-gray-900 dark:text-gray-300"
                }`}
              >
                <span
                  className={`w-2 h-2 me-1 rounded-full ${
                    item.sender.online ? "bg-green-500" : "bg-gray-500"
                  }`}
                ></span>
                {item.sender.online ? "Available" : "Offline"}
              </span>
            </div>
          </li>
        )}
        )
      ) : (
        <li className="py-3 sm:py-4 text-gray-500 dark:text-gray-400">
          No chats available.
        </li>
      )}
    </ul>
  );
};
