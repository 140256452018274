import clsx from "clsx";
import { UserDetails } from "../../../types/userInfo";

import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { useGetUserInfo } from "../../api/hooks/user";





const validationSchema = Yup.object().shape({
  introduce_yourself: Yup.string(),
  hobbies: Yup.string(),
  music_preferred: Yup.string(),
  movies_preferred: Yup.string(),
});

export const FormStepThree = ({
  handleSubmit,
  handleBack,
}: {
  handleSubmit: (values: Partial<UserDetails>) => void;
  handleBack: () => void;
}) => {
  const { data: userData, isLoading: userInfoLoading } = useGetUserInfo();
  const initialValues = {
    introduce_yourself: userData?.introduce_yourself || "",
    hobbies: userData?.hobbies || "",
    music_preferred: userData?.music_preferred || "",
    movies_preferred: userData?.movies_preferred || "",
  };
  return (
    <div className="flex flex-col gap-4 mx-auto w-8/12">
      <p className="text-slate-600 text-xl text-center">
        Tell us a bit about yourself to find your ideal roommate
      </p>
      <div className="flex flex-col">
        <p className="text-lg font-medium">My Hobbies</p>
      </div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, isValid }) => (
          <Form className="space-y-4 p-4">
            <div>
              <label htmlFor="introduce_yourself" className="block font-medium">
                Introduce Yourself
              </label>
              <Field
                name="introduce_yourself"
                as="textarea"
                placeholder="Write something about yourself..."
                className="border p-2 rounded w-full"
              />
            </div>

            <div>
              <label htmlFor="hobbies" className="block font-medium">
                Tell us a bit about you Hobbies
              </label>
              <Field
                name="hobbies"
                placeholder="What are your hobbies?"
                className="border p-2 rounded w-full"
              />
            </div>

            <div>
              <label htmlFor="music_preferred" className="block font-medium">
                What is your preferred Music?
              </label>
              <Field
                name="music_preferred"
                placeholder="Favorite music genre..."
                className="border p-2 rounded w-full"
              />
            </div>

            <div>
              <label htmlFor="movies_preferred" className="block font-medium">
                What are your preferred Movies?
              </label>
              <Field
                name="movies_preferred"
                placeholder="Favorite movie genre..."
                className="border p-2 rounded w-full"
              />
            </div>

            {/* Submit Button */}
            <div className="flex p-4 justify-end gap-4">
              <button
                type="button"
                onClick={handleBack}
                className={clsx(
                "bg-gray-300 text-white px-8 py-2 rounded-lg text-lg cursor-pointer sm:px-16 sm:py-4"
                )}
              >
                Back
              </button>
              <button
                type="submit"
                disabled={isSubmitting || !isValid}
                className={clsx(
                "bg-[#32936F] text-white px-8 py-2 rounded-lg text-lg cursor-pointer sm:px-16 sm:py-4",
                (isSubmitting || !isValid) && "opacity-70"
                )}
              >
                Next
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};
