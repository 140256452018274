import { useState } from "react";
import { ChatMessage } from "../../../types/chatInfo";

export  const Message =({from, message, sender, avatar}: {from:boolean, message: ChatMessage, sender: string, avatar:string}) => {
    const [isDateVisible, setIsDateVisible] = useState(false);

    const toggleDateVisibility = () => {
        setIsDateVisible((prev) => !prev);
      };

    const date = new Date(message.created_at);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getUTCHours()).padStart(2, "0");
    const minutes = String(date.getUTCMinutes()).padStart(2, "0");
  
    const formattedDate = `${year}-${month}-${day}`;
    const timeOnly = `${hours}:${minutes}`;
    const dateTime = `${formattedDate} ${timeOnly}`;

    return(
        <div className={`flex items-start gap-2.5 m-2 items-center ${from ? "flex-row-reverse" : "justify-start"}`} onClick={toggleDateVisibility} >
            {avatar && (
                <img className="w-8 h-8 rounded-full object-cover" src={avatar} alt="Image"/>
            )}
            <div className={`flex flex-col gap-1 w-full max-w-[320px] ${from ? "items-end" : "items-start"}`}>
                <div className="flex items-center space-x-2 rtl:space-x-reverse">
                    <span className="text-sm font-semibold text-gray-900 dark:text-white">{sender}</span>
                    {isDateVisible && (
                        <span className="text-sm font-normal text-gray-500 dark:text-gray-400">
                        {dateTime}
                        </span>
                    )}
                </div>
                <div className={`flex flex-col leading-1.5 p-4 border-gray-200 ${
                                from
                                ? "bg-blue-100 dark:bg-gray-700 rounded-s-xl rounded-ee-xl"
                                : "bg-gray-100 dark:bg-gray-700 rounded-e-xl rounded-es-xl"
                                }`}>
                    <p className="text-sm font-normal text-gray-900 dark:text-white"> {message.message_text}</p>
                    {/* This is for attachments */}
                    {/* {message.attachments && <div className="group relative my-2.5">
                        <div className="absolute w-full h-full bg-gray-900/50 opacity-0 group-hover:opacity-100 transition-opacity duration-300 rounded-lg flex items-center justify-center">
                            <button data-tooltip-target="download-image" className="inline-flex items-center justify-center rounded-full h-10 w-10 bg-white/30 hover:bg-white/50 focus:ring-4 focus:outline-none dark:text-white focus:ring-gray-50">
                                <svg className="w-5 h-5 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 18">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 1v11m0 0 4-4m-4 4L4 8m11 4v3a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-3"/>
                                </svg>
                            </button>
                            <div id="download-image" role="tooltip" className="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700">
                                Download image
                                <div className="tooltip-arrow" data-popper-arrow></div>
                            </div>
                        </div>
                        <img src="/docs/images/blog/image-1.jpg" className="rounded-lg" />
                    </div>} */}
                </div>
                {/* TODO: Logic for Delivered */}
                {/* <span className="text-sm font-normal text-gray-500 dark:text-gray-400">Delivered</span> */}
            </div>
        </div>
    )
}
